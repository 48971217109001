// import request from '../utils/requset';
import request from '../request/http';


const region = {

    //获取区域分页
    regionPage(params) {
        return request.get('/hemei-admin/village/region/page', { params })
    },
    //新增区域
    regionadd(data) {
        return request.post('/hemei-admin/village/region/add', data)
    },
    //修改区域
    regionEdit(data) {
        return request.put('/hemei-admin/village/region/edit', data)
    },
    //更新排序
    sortRegion(data) {
        return request.put('/hemei-admin/village/region/update/sort/index', data)

    },

}

export default region;