import Layout from '@/layout'
const examineRouter = {
    component: Layout,
    path: '/examine',
    name: '审批设置',
    redirect:'examine',
    meta: {
        title: '审批设置',
        icon: ''
    },
    children: [
        {
            path: '/examine/examine',
            name: 'examine',
            meta: { title: "审批设置" },
            component: () => import('../views/examine/examine/index.vue')
        },
        
    ]
}
export default examineRouter
