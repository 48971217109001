import axios from '../request/http';
import { toFormData } from '../common/descriptions'

const setup = {
    // 菜单栏
    menuBar(params) {
        return axios.get("/hemei-admin/menu/getMenuList", { params });
    },
    // 新增修改菜单栏
    routeList(data) {
        return axios.post("/hemei-admin/menu/addOrUpdMenu", data);
    },
    // 删除菜单栏
    routeDl(params) {
        return axios.delete("/hemei-admin/menu/delMenu", { params });
    },
    // 更改菜单状态
    routeDel(params) {
        return axios.delete("/hemei-admin/menu/delMenu", { params });
    },
    // 获取城市列表
    getCityList(params) {
        return axios.get("/service-app/village/children/areaId", { params });
    },
    // 获取乡村资料详情
    getInfoDetail(params) {
        return axios.get("/hemei-admin/villageBaseInfo/detail", { params });
    },
    // 修改乡村资料
    updVillageBaseInfo(data) {
        return axios.post("/hemei-admin/villageBaseInfo/updVillageBaseInfo", data);
    },
    // 获取管理员账号列表
    getVillageManageList(params) {
        return axios.get("/hemei-admin/villageAccount/getVillageManageList", { params });
    },
    // 管理员账号信息修改
    updVillageManageInfo(data) {
        return axios.post("/hemei-admin/villageAccount/updVillageManageInfo", data);
    },
    // 停用、启用账号
    updVillageManageStatus(data) {
        return axios.post("/hemei-admin/villageAccount/updVillageManageStatus", toFormData(data));
    },
    // 获得短信验证码
    getSMSCode(params) {
        return axios.get("/hemei-admin/login/getSMSCode", { params });
    },
    // 管理员绑定用户
    bindUser(params) {
        return axios.get("/hemei-admin/login/bindUser", {
            headers: {
                ...params,
            },
        },);
    },
    // 用户登录
    loginUser(params) {
        return axios.post("/service-user/passport/doPhoneLogin", params );
    },
    // 用户获得短信验证码
    passportgetSMSCode(params) {
        return axios.get("/service-user/passport/getSMSCode", { params });
    },
}

export default setup;