import Layout from '@/layout'
const districtRouter = {
    component: Layout,
    path: '/district',
    name: '区域设置',
    redirect:'district',
    meta: {
        title: '区域设置',
        icon: ''
    },
    children: [
        {
            path: '/district/district',
            name: 'district',
            meta: { title: "区域设置" },
            component: () => import('../views/district/district/index.vue')
        },
        
    ]
}
export default districtRouter
