import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui'
// import * as echarts from 'echarts'
// import Moment from 'moment'
import api from './api'
import VueRouter from 'vue-router'
import store from './store/index'
import preventClick from './utils/clickOnceAtime'
import 'element-ui/lib/theme-chalk/index.css'
import "./common/common.css"
// main.js 或 axios配置文件
import axios from 'axios';
// import DatePicker from '@wangankeji/vue-datepicker'
// import '@wangankeji/vue-datepicker/dist/datepicker.css'
// Vue.use(DatePicker)
Vue.prototype.$axios = axios;
// import Router from 'vue-router'
// import VueWechatTitle from 'vue-wechat-title'
// Vue.use(VueWechatTitle)
// import $ from 'jquery'
// import 'default-passive-events'
// window.jQuery = $
// window.$ = $
Vue.use(VueRouter)
// import 'vue.config.js'
// require("./mock/mock.js")
Vue.use(preventClick)
Vue.use(ElementUI)
// 将api挂载到vue的原型上
Vue.prototype.$api = api;
// Vue.prototype.$echarts = echarts
// Vue.prototype.moment = Moment;
// Vue.config.productionTip = false


/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
// debugger
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

