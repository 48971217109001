// import request from '../utils/requset';
import request from '../request/http';


const contentManagement = {
    // 管理台查询所有文章列表
    getArticleList(params) {
        return request.get('/service-article/article/getArticleList', {params})
    },
    //文章删除
    userDelArticle(params) {
        return request.get('/service-article/article/userDelArticle', { params })
    },
    //文章上下架
    updateStatus(params) {
        return request.get('/service-article/article/updateStatus', { params })
    },
    // 文章详情查询
    articleDetail(params) {
        return request.get('/service-article/article/articleDetail', { params })
    },
    // 查询文章类型列表
    getArticleCategory(params) {
        return request.get('/service-article/portal/article/getArticleCategory', { params })
    },
    // 保存审批人
    saveApproval(data) {
        return request.post('/hemei-admin/approval/save', data)
    },
    // 获取审批分页
    pageApproval(params) {
        return request.get('/hemei-admin/approval/workflow/page', {params})
    },
    // 获取资讯评论树形结构
    getArticleCommentTree(params) {
        return request.get('/service-article/comment/getArticle/CommentTree', {params})
    },
    
}

export default contentManagement;