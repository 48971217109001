// import request from '../utils/requset';
import request from '../request/http';


const up = {

    //乡村用户导入模版下载
    downloadModel(data) {
        return request.post('/service-app/file/downloadModel', data)
    },
    //乡村用户导入模版上传
    uploadModel(data) {
        return request.post('/service-app/file/uploadModel',data)
    },


}

export default up;