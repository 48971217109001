import Layout from '@/layout'
const userRouter = {
    component: Layout,
    path: '/userListManagement',
    name: '乡村用户管理',
    redirect:'userList',
    meta: {
        title: '乡村用户管理',
        icon: ''
    },
    children: [
        {
            path: '/userList',
            name: 'userList',
            meta: { title: "乡村用户列表" },
            component: () => import('../views/userListManagement/userList/list.vue')
        },
        {
            path: "/applyFor",
            name: "applyFor",
            meta: { title: "申请审核" },
            component: () => import('../views/userListManagement/applyFor/list.vue')
        },
    ]
}
export default userRouter
